<template>
  <div>
    <div class="flex items-center">
      <div class="text-xl font-medium ml-1">{{ rate.rating }}</div>
      <div v-for="star in 5" :key="`show-material-star-${star}`">
        <v-icon
          v-if="star <= rate.rating"
          icon="fa: fa-solid fa-star"
          :size="16"
          class="mx-px text-rate"
        />
        <v-icon
          v-else
          icon="fa: fa-regular fa-star"
          :size="16"
          class="mx-px text-mevcut-light"
        />
      </div>
    </div>
    <div v-if="rate.note" class="text-sm text-mevcut-light">
      {{ rate.note }}
    </div>
    <div class="text-sm text-mevcut-light mt-2">
      {{ t('by', { name: rate.full_name_user || t('unknown') }) }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Rate } from '~~/composables/useMenuModel'
const { rateModel } = useMenuModel()
const { t } = useI18n()
const props = withDefaults(
  defineProps<{
    rateProps: Rate
  }>(),
  {}
)
const rate = rateModel(props.rateProps)
</script>
